// html {
//   --mdc-checkbox-disabled-selected-checkmark-color: #fff;
//   --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
//   --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
//   --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
//   --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
//   --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
//   --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16
// }

html {
  // custom varibles
  --background-color: #4190f2;
  --border-color: #88a7c4;

  // apply here
  // --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  // --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--background-color);
  --mdc-checkbox-selected-hover-icon-color: var(--background-color);
  --mdc-checkbox-selected-icon-color: var(--background-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--background-color);
  // --mdc-checkbox-unselected-focus-icon-color: #212121;
  // --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--border-color);
  --mdc-checkbox-unselected-pressed-icon-color: var(--border-color);
  --mdc-checkbox-selected-focus-state-layer-color: none;
  --mdc-checkbox-selected-hover-state-layer-color: var(--background-color);
  --mdc-checkbox-selected-pressed-state-layer-color: none;
  // --mdc-checkbox-unselected-focus-state-layer-color: black;
  // --mdc-checkbox-unselected-hover-state-layer-color: black;
  // --mdc-checkbox-unselected-pressed-state-layer-color: black;
  // --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38)
}

// .mat-mdc-checkbox {
//   --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87)
// }

// .mat-mdc-checkbox.mat-primary {
//   --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
//   --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
//   --mdc-checkbox-selected-checkmark-color: white;
//   --mdc-checkbox-selected-focus-icon-color: #3f51b5;
//   --mdc-checkbox-selected-hover-icon-color: #3f51b5;
//   --mdc-checkbox-selected-icon-color: #3f51b5;
//   --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
//   --mdc-checkbox-unselected-focus-icon-color: #212121;
//   --mdc-checkbox-unselected-hover-icon-color: #212121;
//   --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
//   --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
//   --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
//   --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
//   --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
//   --mdc-checkbox-unselected-focus-state-layer-color: black;
//   --mdc-checkbox-unselected-hover-state-layer-color: black;
//   --mdc-checkbox-unselected-pressed-state-layer-color: black
// }

// .mat-mdc-checkbox.mat-warn {
//   --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
//   --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
//   --mdc-checkbox-selected-checkmark-color: white;
//   --mdc-checkbox-selected-focus-icon-color: #f44336;
//   --mdc-checkbox-selected-hover-icon-color: #f44336;
//   --mdc-checkbox-selected-icon-color: #f44336;
//   --mdc-checkbox-selected-pressed-icon-color: #f44336;
//   --mdc-checkbox-unselected-focus-icon-color: #212121;
//   --mdc-checkbox-unselected-hover-icon-color: #212121;
//   --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
//   --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
//   --mdc-checkbox-selected-focus-state-layer-color: #f44336;
//   --mdc-checkbox-selected-hover-state-layer-color: #f44336;
//   --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
//   --mdc-checkbox-unselected-focus-state-layer-color: black;
//   --mdc-checkbox-unselected-hover-state-layer-color: black;
//   --mdc-checkbox-unselected-pressed-state-layer-color: black
// }

// html {
//   --mdc-checkbox-state-layer-size: 40px;
//   --mat-checkbox-touch-target-display: block
// }

.mat-mdc-checkbox {
  // --mdc-form-field-label-text-font: Roboto, sans-serif;
  // --mdc-form-field-label-text-line-height: 20px;
  // --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  // --mdc-form-field-label-text-weight: 400

  .mdc-form-field,
  .mat-internal-form-field {
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-radius: 4px;
      }
    }

    .mdc-label {
      margin-bottom: 0 !important;
    }
  }
}

.active-disabled {
  --mdc-checkbox-disabled-selected-icon-color: var(--background-color);
  --mdc-checkbox-disabled-unselected-icon-color: var(--background-color);
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.87);
}
