html {
  --mdc-icon-button-icon-size: 18px !important;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  // --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  // --mat-icon-button-hover-state-layer-opacity: 0.04;
  // --mat-icon-button-focus-state-layer-opacity: 0.12;
  // --mat-icon-button-pressed-state-layer-opacity: 0.12
}

// html .mat-mdc-icon-button.mat-primary {
//   --mdc-icon-button-icon-color: #3f51b5;
//   --mat-icon-button-state-layer-color: #3f51b5;
//   --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1)
// }

// html .mat-mdc-icon-button.mat-accent {
//   --mdc-icon-button-icon-color: #ff4081;
//   --mat-icon-button-state-layer-color: #ff4081;
//   --mat-icon-button-ripple-color: rgba(255, 64, 129, 0.1)
// }

// html .mat-mdc-icon-button.mat-warn {
//   --mdc-icon-button-icon-color: #f44336;
//   --mat-icon-button-state-layer-color: #f44336;
//   --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1)
// }

html {
  --mat-icon-button-touch-target-display: block
}

// .mat-mdc-icon-button.mat-mdc-button-base {
//   --mdc-icon-button-state-layer-size: 40px;
//   width: var(--mdc-icon-button-state-layer-size);
//   height: var(--mdc-icon-button-state-layer-size);
//   padding: 8px;
// }

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 30px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;


  mat-icon {
    --custom-icon-size: 18px;
    width: var(--custom-icon-size) !important;
    height: var(--custom-icon-size) !important;
    font-size: var(--custom-icon-size) !important;
  }
}


/**
 * Custom Components Specific Styles
 * =================================
 *
 */

.m-icon-button-primary {
  --mdc-icon-button-icon-color: #fff;
  --mat-icon-button-state-layer-color: #fff;
  // --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1);
}
