html {
  // --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  // --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 10px;
  // --mat-dialog-container-max-width: 80vw;
  // --mat-dialog-container-small-max-width: 80vw;
  // --mat-dialog-container-min-width: 0;
  // --mat-dialog-actions-alignment: start;
  // --mat-dialog-actions-padding: 8px;
  // --mat-dialog-content-padding: 20px 24px;
  // --mat-dialog-with-actions-content-padding: 20px 24px;
  // --mat-dialog-headline-padding: 0 24px 9px
}

// html {
//   --mdc-dialog-container-color: white;
//   --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
//   --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6)
// }

html {
  // --mdc-dialog-subhead-font: Roboto, sans-serif;
  // --mdc-dialog-subhead-line-height: 32px;
  // --mdc-dialog-subhead-size: 20px;
  // --mdc-dialog-subhead-weight: bold;
  --mdc-dialog-subhead-tracking: normal;

  // --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  // --mdc-dialog-supporting-text-weight: bold;
  --mdc-dialog-supporting-text-tracking: normal;
}

// .mat-mdc-dialog-container .mdc-dialog__title {
//     line-height: var(--mdc-dialog-subhead-line-height);
//     font-size: var(--mdc-dialog-subhead-size);
//     font-weight: var(--mdc-dialog-subhead-weight);
//     letter-spacing: var(--mdc-dialog-subhead-tracking);
// }

.mat-mdc-dialog-title {
  font-weight: bold !important;
  color: #2A5298 !important;
  margin-bottom: 0 !important;
  padding: 0 24px 9px 24px !important;
}

.mat-mdc-dialog-content {
  padding: 0 24px 20px 24px !important;
  max-height: 80vh !important;

  overflow-y: hidden !important;
}

.mat-mdc-dialog-actions {
  padding: 0px 24px 20px !important;
}


/**
 * Custom Components Specific Styles
 * =================================
 *
 */

.dialog-header {
  display: flex;
  align-items: baseline;
  padding: 4px 30px !important;

  .mat-mdc-dialog-title {
    padding: 4px !important;
  }

  .btn-container {
    margin-left: auto !important;
  }
}

.main-div {
  width: 99%;
  overflow: hidden;
}