@import "./variables";
@import "./updated/m_variable.scss";


.progress-tracker {
  font-family: "SegoeUI", "KhmerOSBattambang" !important;
  margin: 15px;
}

.normal-table {
  margin: 8px 20px;

  .table-sign {
    margin: 0 16px;
    color: #194c5d;
    font-weight: 100;
  }

  tr {
    background: none !important;

    &:hover {
      background: none !important;
    }

    td {
      padding: 7px 0;

      .label {
        width: 200px;
        color: #A3A3A3 !important;
        display: flex;

        &::after {
          content: ("៖");
          margin-left: auto;
          margin-right: 12px;
        }
      }
    }

    .label {
      color: #A3A3A3 !important;
      width: 150px;
    }
  }

  .value {
    word-break: break-all;
    word-wrap: break-word;
    vertical-align: baseline;
  }
}

.title-2 {
  margin-bottom: 12px;
  margin-top: -4px;

  p {
    margin: 4px 0px !important;
    font-size: 15px !important;
    color: $header-color !important;
    font-weight: 700;
  }

  mat-icon {
    height: 20px;
    width: 20px;
  }
}

.title {
  color: #313131;

  .verified,
  .success,
  .approved {
    color: $success !important;
  }

  .locked,
  .return,
  .returned {
    color: $warning !important;
  }

  .rejected {
    color: $rejected !important;
  }

  .new-request {
    color: $new-request !important;
  }

  .assign {
    color: $assign !important;
  }

  .highlight {
    color: $highlight !important;
  }

  .active {
    color: $main-color;
  }

  .verifying,
  .submit,
  .submitted {
    color: $main-color !important;
  }

  .closed {
    color: $closed !important;
  }

  .suspended,
  .inactive {
    color: $suspended !important;
  }

  .reopened,
  .draft {
    color: $re_open !important;
  }

  .normal {
    color: #000000;
    // font-weight: normal !important;
  }

  .progress-title {
    margin: 0;

    span {
      margin-right: 10px;
    }
  }

  p.item {
    margin-bottom: 0px;
    margin-left: 0 !important;
    font-weight: normal;
  }

  .date {
    color: #A3A3A3;
  }
}

.progress-marker {
  padding-top: 8px;
}

.progress-marker::before {
  width: 25px;
  height: 25px;
  // box-shadow: 0px 0px 3px $success;
  border: 8px solid #DBEDE1;
  background-color: $success;
}

.progress-marker::after {
  z-index: 1;
  left: -8px;
  top: 27px;
  height: calc(100% - 19px);
  width: 2px;
  background-color: #53AD72;
}

.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background-color: $success;
}

.progress-step.is-rejected .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background-color: $rejected;
}

.progress-step.new-request .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background-color: $new-request;
}

.main-box {
  padding: 4px 12px 20px 30px;
  width: 100%;

  .progress-text {
    display: flex;
    width: 100%;
    padding: 4px 12px 12px 33px;

    .title {
      margin-bottom: 0 !important;
      width: 100%;
      display: unset !important;
    }

    p,
    h4 {
      font-size: 15px !important;
      padding-bottom: 0.7em;
    }

    p {
      label {
        margin-bottom: 0;
      }
    }

    h4.date {
      margin-bottom: 0;
    }
  }

  .content {
    .new-request {
      color: $new-request;
    }

    ul {
      margin-bottom: 15px;

      li {
        margin: 10px 0px;
      }
    }
  }
}

.progress-tracker--vertical .progress-text {
  padding: 0 12px 5px 0px;
}

// .progress-step:hover .progress-marker::before {
//   opacity: 0.7;
// }

.li-empty {
  border-bottom: 1px solid #eaeaea;
}

.progress-step h4.date {
  min-width: 185px;
}

.comment-box {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-top: 4px;
  position: relative;
  padding: 10px 12px;
  min-width: 50%;

  mat-icon {
    position: absolute;
    top: -11px;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    padding: 2px;
    background-color: white;
    font-size: 18px;
    left: 5px;
  }

  .desc {
    margin: 0px;
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 14px !important;

    span {
      margin-right: 10px;
    }

    .success {
      color: $success;
    }

    .rejected {
      color: $rejected;
    }

    .new-request {
      color: $new-request;
    }
  }
}
