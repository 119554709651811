.mt-12 {
        margin-top: 12px !important;
}

.mt-20 {
        margin-top: 20px !important;
}

.mt-40 {
        margin-top: 40px !important;
}

.mt-neg-4 {
            margin-top: -4px !important;
}

.ml-auto {
        margin-left: auto !important;
}

.ml-4 {
        margin-left: 4px !important;
}

.ml-8 {
        margin-left: 8px !important;
}

.ml-10 {
        margin-left: 10px !important;
}

.ml-12 {
        margin-left: 12px !important;
}

.ml-16 {
        margin-left: 16px !important;
}

.ml-20 {
        margin-left: 20px !important;
}

.ml-32 {
        margin-left: 32px !important;
}

.mb-neg-8 {
        margin-bottom: -8px !important;
}

.mb-neg-12 {
        margin-bottom: -12px !important;
}

.mb-12 {
            margin-bottom: 12px !important;
}

.mb-20 {
        margin-bottom: 20px !important;
}