@import "./m_variable.scss";

@font-face {
  font-family: "KhmerOSBattambang";
  src: url("../../assets/fonts/KhmerOSBattambang.ttf");
}

@font-face {
  font-family: "SegoeUI";
  src: url("../../assets/fonts/SegoeUI.ttf");
}

/* CARD */
.m-card {
  background: white;
  padding: 20px 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .right {
    margin-left: auto;
  }

  .card-title {
    color: $card-title-color;
    font-size: 14px;
  }

  .card-content {
    color: $card-content-color;
    font-weight: bold;
    font-size: 24px;
  }

  .icon-holder {
    background: $container-backgroud;
    padding: 13px;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    mat-icon {
      font-size: 24px;
    }
  }
}
