.min-w-0	{min-width: 0px !important;}
.min-w-1	{min-width: 0.25rem !important;} /* 4px */
.min-w-2	{min-width: 0.5rem !important;} /* 8px */
.min-w-3	{min-width: 0.75rem !important;} /* 12px */
.min-w-4	{min-width: 1rem !important;} /* 16px */
.min-w-5	{min-width: 1.25rem !important;} /* 20px */
.min-w-6	{min-width: 1.5rem !important;} /* 24px */
.min-w-7	{min-width: 1.75rem !important;} /* 28px */
.min-w-8	{min-width: 2rem !important;} /* 32px */
.min-w-9	{min-width: 2.25rem !important;} /* 36px */
.min-w-10	{min-width: 2.5rem !important;} /* 40px */
.min-w-11	{min-width: 2.75rem !important;} /* 44px */
.min-w-12	{min-width: 3rem !important;} /* 48px */
.min-w-14	{min-width: 3.5rem !important;} /* 56px */
.min-w-16	{min-width: 4rem !important;} /* 64px */
.min-w-20	{min-width: 5rem !important;} /* 80px */
.min-w-24	{min-width: 6rem !important;} /* 96px */
.min-w-28	{min-width: 7rem !important;} /* 112px */
.min-w-32	{min-width: 8rem !important;} /* 128px */
.min-w-36	{min-width: 9rem !important;} /* 144px */
.min-w-40	{min-width: 10rem !important;} /* 160px */
.min-w-44	{min-width: 11rem !important;} /* 176px */
.min-w-48	{min-width: 12rem !important;} /* 192px */
.min-w-52	{min-width: 13rem !important;} /* 208px */
.min-w-56	{min-width: 14rem !important;} /* 224px */
.min-w-60	{min-width: 15rem !important;} /* 240px */
.min-w-64	{min-width: 16rem !important;} /* 256px */
.min-w-72	{min-width: 18rem !important;} /* 288px */
.min-w-80	{min-width: 20rem !important;} /* 320px */
.min-w-96	{min-width: 24rem !important;} /* 384px */
.min-w-px	{min-width: 1px !important;}
.min-w-full	{min-width: 100% !important;}
.min-w-min	{min-width: min-content !important;}
.min-w-max	{min-width: max-content !important;}
.min-w-fit	{min-width: fit-content !important;}
