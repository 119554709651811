// html {
//   --mdc-text-button-container-shape: 4px;
//   --mdc-text-button-keep-touch-target: false;

//   --mdc-filled-button-container-shape: 4px;
//   --mdc-filled-button-keep-touch-target: false;

//   --mdc-protected-button-container-shape: 4px;
//   --mdc-protected-button-keep-touch-target: false;

//   --mdc-outlined-button-keep-touch-target: false;
//   --mdc-outlined-button-outline-width: 1px;
//   --mdc-outlined-button-container-shape: 4px;

//   --mat-text-button-horizontal-padding: 8px;
//   --mat-text-button-with-icon-horizontal-padding: 8px;
//   --mat-text-button-icon-spacing: 8px;
//   --mat-text-button-icon-offset: 0;

//   --mat-filled-button-horizontal-padding: 16px;
//   --mat-filled-button-icon-spacing: 8px;
//   --mat-filled-button-icon-offset: -4px;

//   --mat-protected-button-horizontal-padding: 16px;
//   --mat-protected-button-icon-spacing: 8px;
//   --mat-protected-button-icon-offset: -4px;

//   --mat-outlined-button-horizontal-padding: 15px;
//   --mat-outlined-button-icon-spacing: 8px;
//   --mat-outlined-button-icon-offset: -4px
// }

// html {
//   --mdc-text-button-label-text-color: black;
//   --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
//   --mat-text-button-state-layer-color: black;
//   --mat-text-button-disabled-state-layer-color: black;
//   --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
//   --mat-text-button-hover-state-layer-opacity: 0.04;
//   --mat-text-button-focus-state-layer-opacity: 0.12;
//   --mat-text-button-pressed-state-layer-opacity: 0.12;

//   --mdc-filled-button-container-color: white;
//   --mdc-filled-button-label-text-color: black;
//   --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
//   --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
//   --mat-filled-button-state-layer-color: black;
//   --mat-filled-button-disabled-state-layer-color: black;
//   --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
//   --mat-filled-button-hover-state-layer-opacity: 0.04;
//   --mat-filled-button-focus-state-layer-opacity: 0.12;
//   --mat-filled-button-pressed-state-layer-opacity: 0.12;

//   --mdc-protected-button-container-color: white;
//   --mdc-protected-button-label-text-color: black;
//   --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
//   --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
//   --mdc-protected-button-container-shadow-color: #000;
//   --mat-protected-button-state-layer-color: black;
//   --mat-protected-button-disabled-state-layer-color: black;
//   --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
//   --mat-protected-button-hover-state-layer-opacity: 0.04;
//   --mat-protected-button-focus-state-layer-opacity: 0.12;
//   --mat-protected-button-pressed-state-layer-opacity: 0.12;

//   --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
//   --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
//   --mdc-outlined-button-label-text-color: black;
//   --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
//   --mat-outlined-button-state-layer-color: black;
//   --mat-outlined-button-disabled-state-layer-color: black;
//   --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
//   --mat-outlined-button-hover-state-layer-opacity: 0.04;
//   --mat-outlined-button-focus-state-layer-opacity: 0.12;
//   --mat-outlined-button-pressed-state-layer-opacity: 0.12
// }

// .mat-mdc-button.mat-primary {
//   --mdc-text-button-label-text-color: #3f51b5;
//   --mat-text-button-state-layer-color: #3f51b5;
//   --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1)
// }

// .mat-mdc-button.mat-accent {
//   --mdc-text-button-label-text-color: #ff4081;
//   --mat-text-button-state-layer-color: #ff4081;
//   --mat-text-button-ripple-color: rgba(255, 64, 129, 0.1)
// }

// .mat-mdc-button.mat-warn {
//   --mdc-text-button-label-text-color: #f44336;
//   --mat-text-button-state-layer-color: #f44336;
//   --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1)
// }

// .mat-mdc-unelevated-button.mat-primary {
//   --mdc-filled-button-container-color: #3f51b5;
//   --mdc-filled-button-label-text-color: white;
//   --mat-filled-button-state-layer-color: white;
//   --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-unelevated-button.mat-accent {
//   --mdc-filled-button-container-color: #ff4081;
//   --mdc-filled-button-label-text-color: white;
//   --mat-filled-button-state-layer-color: white;
//   --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-unelevated-button.mat-warn {
//   --mdc-filled-button-container-color: #f44336;
//   --mdc-filled-button-label-text-color: white;
//   --mat-filled-button-state-layer-color: white;
//   --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-raised-button.mat-primary {
//   --mdc-protected-button-container-color: #3f51b5;
//   --mdc-protected-button-label-text-color: white;
//   --mat-protected-button-state-layer-color: white;
//   --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-raised-button.mat-accent {
//   --mdc-protected-button-container-color: #ff4081;
//   --mdc-protected-button-label-text-color: white;
//   --mat-protected-button-state-layer-color: white;
//   --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-raised-button.mat-warn {
//   --mdc-protected-button-container-color: #f44336;
//   --mdc-protected-button-label-text-color: white;
//   --mat-protected-button-state-layer-color: white;
//   --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1)
// }

// .mat-mdc-outlined-button.mat-primary {
//   --mdc-outlined-button-label-text-color: #3f51b5;
//   --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
//   --mat-outlined-button-state-layer-color: #3f51b5;
//   --mat-outlined-button-ripple-color: rgba(63, 81, 181, 0.1)
// }

// .mat-mdc-outlined-button.mat-accent {
//   --mdc-outlined-button-label-text-color: #ff4081;
//   --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
//   --mat-outlined-button-state-layer-color: #ff4081;
//   --mat-outlined-button-ripple-color: rgba(255, 64, 129, 0.1)
// }

// .mat-mdc-outlined-button.mat-warn {
//   --mdc-outlined-button-label-text-color: #f44336;
//   --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
//   --mat-outlined-button-state-layer-color: #f44336;
//   --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1)
// }

// html {
//   --mdc-text-button-container-height: 36px;
//   --mdc-filled-button-container-height: 36px;
//   --mdc-outlined-button-container-height: 36px;
//   --mdc-protected-button-container-height: 36px;

//   --mat-text-button-touch-target-display: block;
//   --mat-filled-button-touch-target-display: block;
//   --mat-protected-button-touch-target-display: block;
//   --mat-outlined-button-touch-target-display: block
// }

html {
  --mdc-text-button-label-text-font: "SegoeUI", "KhmerOSBattambang";
  // --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  // --mdc-text-button-label-text-weight: 500;
  // --mdc-text-button-label-text-transform: none;

  --mdc-filled-button-label-text-font: "SegoeUI", "KhmerOSBattambang";
  // --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  // --mdc-filled-button-label-text-weight: 500;
  // --mdc-filled-button-label-text-transform: none;

  --mdc-outlined-button-label-text-font: "SegoeUI", "KhmerOSBattambang";
  // --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  // --mdc-outlined-button-label-text-weight: 500;
  // --mdc-outlined-button-label-text-transform: none;

  --mdc-protected-button-label-text-font: "SegoeUI", "KhmerOSBattambang";
  // --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  // --mdc-protected-button-label-text-weight: 500;
  // --mdc-protected-button-label-text-transform: none
}


/**
 * Custom Components Specific Styles
 * =================================
 *
 */

.mat-mdc-raised-button:disabled {
  color: var(--mdc-protected-button-disabled-label-text-color) !important;
  background-color: var(--mdc-protected-button-disabled-container-color) !important;
}

.mat-mdc-button:disabled {
  color: var(--mdc-protected-button-disabled-label-text-color) !important;
  background-color: var(--mdc-protected-button-disabled-container-color) !important;
}

.m-button-primary {
  --mdc-text-button-label-text-color: #fff;
  --mat-text-button-state-layer-color: #fff;

  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;

  --mdc-outlined-button-label-text-color: #fff;
  --mdc-outlined-button-outline-color: #fff;
  --mat-outlined-button-state-layer-color: #fff;

  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
}

.mat-mdc-button>.mat-icon,
.mat-mdc-text-button>.mat-icon,
.mat-mdc-filled-button>.mat-icon,
.mat-mdc-protected-button>.mat-icon,
.mat-mdc-unelevated-button>.mat-icon,
.mat-mdc-raised-button>.mat-icon,
.mat-mdc-outlined-button>.mat-icon {
  --icon-size: 22px;
  width: var(--icon-size) !important;
  height: var(--icon-size) !important;
  font-size: var(--icon-size) !important;
}

.s-sm {
  padding: 0 16px !important;
}

.s-md {
  padding: 21px 20px !important;
}

.s-m {
  padding: 22px 20px !important;
}

.s-lg {
  padding: 23px 24px !important;
}
