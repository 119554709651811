@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/card";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/dropdown";
@import "./variables";


label {
  &.dropdown-item {
    margin: 0 !important;
  }
}

div.invalid-feedback {
  display: block;
}

.ng-touched {
  &.ng-invalid {
    @extend .is-invalid;
    // .dropdown-toggle {
    //   &::after {
    //     border-top-color: #dc3545;
    //     border-bottom-color: #dc3545;
    //   }
    // }
  }
}

ngx-intl-tel-input {
  &.ng-touched {
    &.ng-invalid {
      .iti {
        .form-control {
          @extend .is-invalid;
        }
      }
    }
  }
}

.datepicker-control {
  &.ng-touched {
    &.ng-invalid {
      .form-control {
        @extend .is-invalid
      }
    }
  }
}

.is-invalid {
  border-color: #dc3545 !important;
  background-image: unset !important;
}

.invalid-control {
  border-color: #dc3545;

  button {
    border-color: #dc3545 !important;
  }
}

.invalid-photo {
  border: 1px solid #dc3545 !important;
}

.dropdown-menu {
  &.dropdown-search {
    padding-top: 0;
    .search {
      position: sticky;
      top: 0;
    }
  }
}

.warning {
  color: #dc3545 !important;
}

// .mb-2 {
//   // width >= 426
//   @media (min-width: 426px) {
//     margin-bottom: 0 !important;
//   }

//   // width <= 425
//   @media (max-width: 425px) {
//     margin-bottom: 0.5rem !important;
//   }
// }

// .mb-4 {
//   // width >= 426
//   @media (min-width: 426px) {
//     margin-bottom: 0 !important;
//   }

//   // width <= 425
//   @media (max-width: 425px) {
//     margin-bottom: 1.5rem !important;
//   }
// }

.text-danger {
  color: crimson;
  font-size: 14px;
}

.list-file-name {
  width: calc(100% - 150px);
  height: 100%;
  float: left;
  padding-top: 8px;

  &:hover {
    cursor: pointer;
    background-color: #fdfdfd;
  }
}

.list-icon-clear {
  width: 5%;
  height: 100%;
  float: right;
  padding-top: 8px;
}
.list-file-body {
  width: 100%;
  height: 40px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.list-file {
  box-shadow: $default-shadow;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 51px;
  margin-bottom: 10px;
}
.list-file-reservation {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}
.list-moc-file:hover {
  cursor: pointer;
  background-color: #fafafa;
}
.textFile {
  font-size: 14px;
  margin-left: 15px;
  overflow: hidden;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card {
  box-shadow: $default-shadow;
  border-radius: 5px;
  margin-bottom: 15px;
  .card-header {
    color: $card-title-color;
    background-color: $card-title-background-color;
    border-radius: 5px 5px 0 0;
    font-family: "SegoeUI", "KhmerOSBattambang" !important;
    cursor: pointer;
  }
  .list-business {
    box-shadow: $default-shadow;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .marginTop {
    margin-top: 20px;
  }
  .line {
    background-color: gray;
    height: 0.5px;
  }
  .colorPrimary {
    background-color: $card-title-background-color;
  }
  .btn-collapse {
    border-radius: 50%;
    float: right;
    background-color: transparent;
    color: $card-title-color;
    font-family: "Material Icons";
    text-align: center;
    cursor: pointer;
    line-height: 1;
    &::after {
      content: "remove_circle";
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  &.collapse {
    .card-header {
      border-radius: 5px;
      .btn-collapse::after {
        content: "add_circle";
      }
    }

    .card-body {
      display: none;
    }
  }
}

.form-row {
  label {
    color: $value;
    margin-bottom: 10px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline {
    margin: 0px;
    padding: 0px;
    height: 42px;
    &:focus {
      color: $form-control-color;
    }
    font-size: 14px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding: 0;
    border-top: 0 solid transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 0.75em;
    margin-top: -0.01em;
    position: relative;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    // margin-top: -0.2em;
    margin: -0.2em 0 0 0;
    padding-bottom: unset;
  }

  .mat-mdc-input-element {
    margin-bottom: 3px;
  }

  .form-control {
    font-size: 14px;
    color: $form-control-color;
    height: 43px;
    &:focus {
      color: $form-control-color;
    }
  }
  .input-group {
    .input-group-text {
      font-size: 14px;
      border-radius: 0.25rem 0 0 0.25rem;

      .material-icons {
        font-size: 16px;
      }
    }
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #a8a8a8;
  }

  ::placeholder {
    color: #a8a8a8;
  }
  .form-check {
    padding-left: 24px;
    label.form-check-label {
      color: $form-control-color;
      margin-bottom: 0;
    }
  }
  .form-check-inline {
    margin-right: 50px;
    padding: 0;
    padding-left: 3px;
    margin-bottom: 17px;
  }

  &.inline {
    label {
      color: $form-control-color;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5;
    }
    .note {
      color: $form-label-color;
    }
  }
  @media (min-width: 768px) {
    .md-right {
      text-align: right;
    }
  }
}

.danger {
  color: #76001b;
}

.dropdown, .dropup, .app-select {
  button {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 43px;
    background-color: white;
    padding: 0px 12px;
    text-align: start;
    position: relative;

    p {
      margin: 0px;
      width: 97%;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 32px;
    }

    &::after {
      position: absolute;
      top: 19px;
      right: 10px;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    transform: translate3d(5px, 35px, 0px) !important;
    top: 5px !important;
    left: -5px !important;

    p, label {
      margin-bottom: 0px;
      font-size: 14px;
      color: #0c3c31;
      white-space: normal;
    }

    .txt-search {
      width: 95%;
      margin: 0px auto;
      height: 35px;
    }

    .list-item {
      max-height: 225px;
      overflow: auto;
      cursor: pointer;
    }
  }

  input {
    font-size: 14px;
  }

  .dropdown-toggle {
    p {
      color: $form-control-color;
    }
  }
}

.business-objective-dropdown {
  width: calc(100% - 200px);

  .btn-business-objective {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input {
    font-size: 14px;
  }

  p.code {
    float: left;
  }

  p.objective-desc {
    margin-left: 36px;
    margin-top: 0px;
    width: 95%;
  }

  p.activity-desc {
    margin-left: 51px;
    margin-top: 0px;
    width: 95%;
  }
}

.input-group-prepend {
  height: 43px;
}

.share-count {
  position: relative;

  .span-activity {
    width: 125px;
  }

  .cancel {
    color: $cancel;
    position: absolute;
    right: 8px;
    top: 9px;
    z-index: 1;
    cursor: pointer;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-start {
  border-width: 1px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end {
  border-width: 1px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: #dc3545 !important;
}
